import React, { Component } from 'react'
import '@animxyz/react/dist/ReactAnimXyz'
import './App.css'
import NavigationBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'
import MainBody from './components/MainBody/MainBody'
import Docs from './components/Docs/Docs'
import HowTo from './components/HowTo/HowTo'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

class App extends Component {
  
  render() {
    
    return(
      <div>
        <Router>
          <NavigationBar/>
          <Switch>
            <Route path="/" exact component={() => <MainBody />} />
            <Route path="/installation" exact component={() => <HowTo />} />
            <Route path="/docs" exact component={() => <Docs />} />
          </Switch>
          <Footer />
        </Router>
      </div>
    )

  }

}
export default App
