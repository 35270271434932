import React, { Component } from 'react'
import '@animxyz/core/dist/animxyz.min.css'
import './MainBody.css'
import {Container, Col, Row} from 'reactstrap/dist/reactstrap.js'
import {AiFillCopy, AiOutlineDownload} from 'react-icons/ai'
import {GoLinkExternal} from 'react-icons/go'
import {Link, withRouter} from 'react-router-dom'

class MainBody extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {

        function copyText() {
            navigator.clipboard.writeText('git clone https://github.com/kav2007/invaderssss.git')
        }

        return(
            
            <Container fluid id="main-body-id">
                <div className="heading">
                    <h1 className="typewriter tagline" autofocus>The basic-est python library that anybody and everybody can use</h1>
                    <div  className="engage-animxyz-animation">
                        <img src={`${process.env.PUBLIC_URL}/assets/logo192.png`}  alt="The Invaderssss" className="xyz-in invaderssss-logo"/>
                    </div>
                    <Row>
                        <Col md="6">
                            <a className="face-button" href="https://drive.google.com/file/d/1jicS4eQUnb_J_Lens_Ss3a10UEuc3rOj/view?usp=sharing" target="_blank" rel="noreferrer">
                                <div className="face-primary hvr-underline-from-center">
                                    <AiOutlineDownload className="icon" />
                                    Download
                                </div>
                            </a>
                        </Col>
                        <Col md="6" className="button-id" id="git-clone-parent">
                            <span id="git-clone-id"><AiFillCopy className="copy-icon" onClick={copyText()}/>    git clone https://github.com/kav2007/invaderssss.git</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="card">
                                <div className="card-heading">Installation</div>
                                <hr className="hr-card"/>
                                <section className="card-para">
                                    <ul>
                                        <li><Link to="/installation" className="card-links">Installing Invaderssss through zip or git</Link></li>
                                        <li><Link to="/installation" className="card-links">Creating a new python file</Link></li>
                                        <li><Link to="/installation" className="card-links">Importing Invaderssss</Link></li>
                                        <li><Link to="/installation" className="card-links">Using Invaderssss</Link></li>
                                    </ul>
                                    <Link className="face-button card-button-1" to="/installation">
                                        <span className="face-primary hvr-underline-from-center">
                                            <GoLinkExternal className="icon" />
                                            Visit
                                        </span>
                                    </Link>
                                </section>
                            </div>
                        </Col>
                        <Col md="6">
                        <div className="card">
                                <div className="card-heading">Documentation</div>
                                <hr className="hr-card"/>
                                <section className="card-para">
                                    <ul>
                                        <li><Link to="/docs" className="card-links">Basic Arithmetic Operations</Link></li>
                                        <li><Link to="/docs" className="card-links">Playing with numbers</Link></li>
                                        <li><Link to="/docs" className="card-links">Area, Perimeter, Volume and Surface Areas</Link></li>
                                        <li><Link to="/docs" className="card-links">String Related Functions</Link></li>
                                        <li><Link to="/docs" className="card-links">Mathematics In Daily Life</Link></li>
                                        <li><Link to="/docs" className="card-links">Geometry Related</Link></li>
                                        <li><Link to="/docs" className="card-links">Conversions</Link></li>
                                        <li><Link to="/docs" className="card-links">Science Related Functions</Link></li>
                                    </ul>
                                    <Link className="face-button" to="/docs">
                                        <span className="face-primary hvr-underline-from-center">
                                            <GoLinkExternal className="icon" />
                                            Visit
                                        </span>
                                    </Link>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

        )
    
    }

}

export default withRouter(MainBody)