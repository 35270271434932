import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Docs.css'
import '@animxyz/react/dist/ReactAnimXyz'
import {} from 'reactstrap/dist/reactstrap.js'

class Docs extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props);

        this.state= {
            arithmetic_operators: [
                {
                    id: 0,
                    name: "add(x,y)",
                    text: "The add function is a simple function that lets you add two numbers and returns you the sum",
                },
                {
                    id: 1,
                    name: "add_multiple(*args)",
                    text: "The add_multiple function is a simple function that lets you add any amount of number and returns the sum",
                },
                {
                    id: 2,
                    name: "subtract(x,y)",
                    text: "The subtract function is a simple function that lets you subtract one number from another and returns the difference",
                },
                {
                    id: 3,
                    name: "multiple(x,y)",
                    text: "The multiply function is a simple function that lets you multiply 2 number and returns the product",
                },
                {
                    id: 4,
                    name: "multiply_multiple(*args)",
                    text: "The multiply_multiple function is a simple function that lets you multiply any amount of number and returns the profuct",
                },
                {
                    id: 5,
                    name: "divide(x,y)",
                    text: "The divide function is a simple function that lets you divide a number by another and returns the quotient",
                },
                {
                    id: 6,
                    name: "remainder(x,y)",
                    text: "The remainder function is a simple function that lets you divide a number by another and returns the remainder",
                },
                {
                    id: 7,
                    name: "square(x)",
                    text: "The square function is a simple function that lets you find the square of a number and returns it",
                },
                {
                    id: 8,
                    name: "cube(x)",
                    text: "The cube function is a simple function that lets you find the cube of a number and returns it",
                },
                {
                    id: 9,
                    name: "exponential(x,y)",
                    text: "The exponential function is a simple function that lets you find the value of y raised to x and returns it",
                },
                {
                    id: 10,
                    name: "sq_root(x)",
                    text: "The sq_root function is a simple function that lets you find the square route of the number and returns it",
                },
                {
                    id: 11,
                    name: "cb_root(x)",
                    text: "The cb_root function is a simple function that lets you find the cube root of the number and returns it",
                },
                {
                    id: 12,
                    name: "root(x,y)",
                    text: "The root function lets you find the root to any extent like 4,5 of the number, x and returns it",
                },
                {
                    id: 13,
                    name: "percentage(x,y)",
                    text: "The percentage function finds the x% of y and returns it",
                },
                {
                    id: 14,
                    name: "fraction_sum(*args)",
                    text: "The fraction_sum function lets you find the sum of the fractions and returns the sum. THE FRACTIONS SHOULD BE INPUTTED AS STRINGS WITH DOUBLE QUOTES",
                },
                {
                    id: 15,
                    name: "fraction_subtract(x,y)",
                    text: "The fraction_subtract function lets you find the differnce of the two fractions. THE FRACTIONS SHOULD BE INPUTTED AS STRINGS WITH DOUBLE QUOTES",
                },
                {
                    id: 16,
                    name: "fraction_multiply(*args)",
                    text: "The fraction_multiply function lets you find the product of the fraction and returns the product. THE FRACTIONS SHOULD BE INPUTTED AS STRINGS WITH DOUBLE QUOTES",
                },
                {
                    id: 17,
                    name: "fraction_divide(x,y)",
                    text: "The fraction_divide function lets you divide x by y and returns the quotient. THE FRACTIONS SHOULD BE INPUTTED AS STRINGS WITH DOUBLE QUOTES",
                },
                {
                    id: 18,
                    name: "number_to_roman(num)",
                    text: "The number_to_roman function lets you convert a number it to its roman equivilant",
                },
                {
                    id: 19,
                    name: "roman_to_number(roman_num)",
                    text: "The roman_to_number function lets you convert a roman number to its number equivilant",
                },
                {
                    id: 20,
                    name: "x_in_quadratic(a,b,c)",
                    text: "The x_in_quadratic function lets you find the x in quadrartic function with a,b,c given as paramater, and returns the x",
                },
            ],
            area_perimeter_volume: [
                {
                    id: 0,
                    name: "area_square(side)",
                    text: "The area_square function lets you find the area of the square of the side inputted and returns it",
                },
                {
                    id: 1,
                    name: "area_rectangle(length, breadth)",
                    text: "The area_reactangle function lets you find the area of the rectangle of the length and breadth inputted and returns it",
                },
                {
                    id: 2,
                    name: "area_equilateral_triangle(side)",
                    text: "The area_equilateral_triangle function lets you find the area of the equilateral triangle of the side inputted and returns it",
                },
                {
                    id: 3,
                    name: "area_heron_triangle(side1,side2,side3)",
                    text: "The area_heron_triangle function lets you find the area of the triangle using the heron's formula of the 3 sides inputted and returns it",
                },
                {
                    id: 4,
                    name: "area_parallelogram(base,height)",
                    text: "The area_parallelogram function lets you find the area of the parallelogram of the base and height inputted and returns it",
                },
                {
                    id: 5,
                    name: "area_rhombus(diagonal1,diagonal2)",
                    text: "The area_rhombus function lets you find the area of the rhombus of the 2 diagonals inputted and returns it",
                },
                {
                    id: 6,
                    name: "area_rhombus_parallelogram_way(base,height)",
                    text: "The area_rhombus_parallelogram_way function lets you find the area of the rhombus of the base and height inputted and returns it",
                },
                {
                    id: 7,
                    name: "area_circle(r)",
                    text: "The area_circle function lets you find the area of the circle of the radius inputted and returns it",
                },
                {
                    id: 8,
                    name: "area_trapezium(base1,base2,height)",
                    text: "The area_trapezium function lets you find the area of the trapezium of the 2 bases and height inputted and returns it",
                },
                {
                    id: 9,
                    name: "area_kite(diagonal1,diagonal2)",
                    text: "The area_kite function lets you find the area of the kite of the 2 diagonals inputted and returns it",
                },
                {
                    id: 10,
                    name: "area_ellipse(axis1,axis2)",
                    text: "The area_ellipse function lets you find the area of the ellipse of the 2 axes inputted and returns it",
                },
                {
                    id: 11,
                    name: "peri_square(side)",
                    text: "The peri_square function lets you find the perimeter of the square of the side inputted and returns it",
                },
                {
                    id: 12,
                    name: "peri_rectangle(length,breadth)",
                    text: "The peri_rectangle function lets you find the perimeter of the rectangle of the length and breadth inputted and returns it",
                },
                {
                    id: 13,
                    name: "peri_triangle(side1,side2,side3)",
                    text: "The peri_triangle function lets you find the perimeter of the triangle of the 3 sides inputted and returns it",
                },
                {
                    id: 14,
                    name: "peri_parallelogram(parallel1,parallel2)",
                    text: "The peri_parallelogram function lets you find the perimeter of the parallelogram of the 2 parallel sides inputted and returns it",
                },
                {
                    id: 15,
                    name: "peri_rhombus(side)",
                    text: "The peri_rhombus function lets you find the perimeter of the rhombus of the side inputted and returns it",
                },
                {
                    id: 16,
                    name: "circum_circle(r)",
                    text: "The circum_circle function lets you find the circumference of the circle of the radius inputted and returns it",
                },
                {
                    id: 17,
                    name: "peri_trapezium(side1,side2,side3,side4)",
                    text: "The peri_trapezium function lets you find the perimeter of the trapezium of the 4 sides inputted and returns it",
                },
                {
                    id: 18,
                    name: "peri_kite(side1,side2)",
                    text: "The peri_kite function lets you find the perimeter of the kite of the 2 sides inputted and returns it",
                },
                {
                    id: 19,
                    name: "vol_cube(edge)",
                    text: "The vol_cube function lets you find the volume of the cube of the edge inputted and returns it",
                },
                {
                    id: 20,
                    name: "vol_cuboid(length,breadth,height)",
                    text: "The vol_cuboid function lets you find the volume of the cuboid of the length, breadth, height inputted and returns it",
                },
                {
                    id: 21,
                    name: "vol_cylinder(radius, height)",
                    text: "The vol_cylinder function lets you find the volume of the cylinder of the radius and height inputted and returns it",
                },
                {
                    id: 22,
                    name: "vol_cone(radius, height)",
                    text: "The vol_cone function lets you find the volume of the cone of the radius and height inputted and returns it",
                },
                {
                    id: 23,
                    name: "vol_sphere(radius)",
                    text: "The vol_sphere function lets you find the volume of the sphere of the radius inputted and returns it",
                },
                {
                    id: 24,
                    name: "vol_hemisphere(radius)",
                    text: "The vol_hemishphere function lets you find the volume of the hemisphere of the radius inputted and returns it",
                },
                {
                    id: 25,
                    name: "vol_triangular_prism (a,b,c,h)",
                    text: "The vol_triangular_prism function lets you find the volume of the triangular prism of the parameters inputted and returns it",
                },
                {
                    id: 26,
                    name: "vol_square_prism (a,h)",
                    text: "The vol_square_prism function lets you find the volume of the square prism of the parameters inputted and returns it",
                },
                {
                    id: 27,
                    name: "vol_rectangular_prism (l,w,h)",
                    text: "The vol_rectangular_prism function lets you find the volume of the rectangular prism of the parameters inputted and returns it",
                },
                {
                    id: 28,
                    name: "vol_pentagonal_prism(a,h)",
                    text: "The vol_pentagonal_prism function lets you find the volume of the pentagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 29,
                    name: "vol_hexagonal_prism(a,h)",
                    text: "The vol_hexagonal_prism function lets you find the volume of the hexagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 30,
                    name: "vol_octagonal_prism(a,h)",
                    text: "The vol_octagonal_prism function lets you find the volume of the octagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 31,
                    name: "vol_tetrahedron(a)",
                    text: "The vol_tetrahadron function lets you find the volume of the tetrahadron of the parameters inputted and returns it",
                },
                {
                    id: 32,
                    name: "vol_square_pyramid(a,h)",
                    text: "The vol_square_pyramid function lets you find the volume of the square pyramid of the parameters inputted and returns it",
                },
                {
                    id: 33,
                    name: "vol_rectangular_pyramid(l,w,h)",
                    text: "The vol_rectangular_pyramid function lets you find the volume of the rectangular pyramid of the parameters inputted and returns it",
                },
                {
                    id: 34,
                    name: "vol_hexagonal_pyramid(a,h)",
                    text: "The vol_hexagonal_pyramid function lets you find the volume of the hexagonal pyramid of the parameters inputted and returns it",
                },
                {
                    id: 35,
                    name: "vol_torus(R,r)",
                    text: "The vol_torus function lets you find the volume of the torus of the parameters inputted and returns it",
                },
                {
                    id: 36,
                    name: "tsa_cube(edge)",
                    text: "The tsa_cube function lets you find the total surface area of the cube of the parameters inputted and returns it",
                },
                {
                    id: 37,
                    name: "lsa_cube(edge)",
                    text: "The lsa_cube function lets you find the lateral surface area of the cube of the parameters inputted and returns it",
                },
                {
                    id: 38,
                    name: "tsa_cuboid(length,breadth,height)",
                    text: "The tsa_cuboid function lets you find the total surface area of the cuboid of the parameters inputted and returns it",
                },
                {
                    id: 39,
                    name: "lsa_cuboid(length,breadth,height)",
                    text: "The lsa_cuboid function lets you find the lateral surface area of the cuboid of the parameters inputted and returns it",
                },
                {
                    id: 40,
                    name: "tsa_cylinder(radius,height)",
                    text: "The tsa_cylinder function lets you find the total surface area of the cylinder of the parameters inputted and returns it",
                },
                {
                    id: 41,
                    name: "lsa_cylinder(radius,height)",
                    text: "The lsa_cylinder function lets you find the lateral surface area of the cylinder of the parameters inputted and returns it",
                },
                {
                    id: 42,
                    name: "tsa_cone(slant_height,radius)",
                    text: "The tsa_cone function lets you find the total surface area of the cone of the parameters inputted and returns it",
                },
                {
                    id: 43,
                    name: "lsa_cone(slant_height,r)",
                    text: "The lsa_cone function lets you find the lateral surface area of the cone of the parameters inputted and returns it",
                },
                {
                    id: 44,
                    name: "tsa_sphere(radius)",
                    text: "The tsa_sphere function lets you find the total surface area of the sphere of the parameters inputted and returns it",
                },
                {
                    id: 45,
                    name: "tsa_hemisphere(radius)",
                    text: "The tsa_hemisphere function lets you find the total surface area of the hemisphere of the parameters inputted and returns it",
                },
                {
                    id: 46,
                    name: "lsa_hemisphere(radius)",
                    text: "The lsa_hemisphere function lets you find the lateral surface area of the hemisphere of the parameters inputted and returns it",
                },
                {
                    id: 47,
                    name: "tsa_triangular_prism(a,b,c,h)",
                    text: "The tsa_triangular_prism function lets you find the total surface area of the triangular prism of the parameters inputted and returns it",
                },
                {
                    id: 48,
                    name: "tsa_square_prism(a,h)",
                    text: "The tsa_square_prism function lets you find the total surface area of the square prism of the parameters inputted and returns it",
                },
                {
                    id: 49,
                    name: "tsa_rectangular_prism(l,w,h)",
                    text: "The tsa_rectangular_prism function lets you find the total surface area of the rectangular_prism of the parameters inputted and returns it",
                },
                {
                    id: 50,
                    name: "tsa_pentagonal_prism(a,h)",
                    text: "The tsa_pentagonal_prism function lets you find the total surface area of the pentagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 51,
                    name: "tsa_hexagonal_prism(a,h)",
                    text: "The tsa_hexagonal_prism function lets you find the total surface area of the hexagonal_prism of the parameters inputted and returns it",
                },
                {
                    id: 52,
                    name: "tsa_ocatagonal_prism(a,h)",
                    text: "The tsa_octagonal_prism function lets you find the total surface area of the octagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 53,
                    name: "lsa_triangular_prism(a,b,c,h)",
                    text: "The lsa_triangular_prism function lets you find the lateral surface area of the triangular prism of the parameters inputted and returns it",
                },
                {
                    id: 54,
                    name: "lsa_pentagonal_prism(a,h)",
                    text: "The lsa_pentagonal_prism function lets you find the lateral surface area of the pentagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 55,
                    name: "lsa_hexagonal_prism(a,h)",
                    text: "The lsa_hexagonal_prism function lets you find the lateral surface area of the hexagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 56,
                    name: "lsa_octagonal_prism(a,h)",
                    text: "The lsa_octagonal_prism function lets you find the lateral surface area of the octagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 57,
                    name: "tsa_tetrahedron(a)",
                    text: "The tsa_tetrahadron function lets you find the total surface area of the tetrahadron of the parameters inputted and returns it",
                },
                {
                    id: 58,
                    name: "tsa_rectangular_pyramid(l,w,h)",
                    text: "The tsa_rectangular_pyramid function lets you find the total surface area of the rectangular pyramid of the parameters inputted and returns it",
                },
                {
                    id: 59,
                    name: "tsa_square_pyramid(a,h)",
                    text: "The tsa_square_pyramid function lets you find the total surface area of the square_pyramid of the parameters inputted and returns it",
                },
                {
                    id: 60,
                    name: "tsa_hexagonal_pyramid(a,h)",
                    text: "The tsa_hexagonal_prism function lets you find the total surface area of the hexagonal prism of the parameters inputted and returns it",
                },
                {
                    id: 61,
                    name: "lsa_square_pyramid(a,h)",
                    text: "The lsa_square_pyramid function lets you find the lateral surface area of the square pyramid of the parameters inputted and returns it",
                },
                {
                    id: 62,
                    name: "lsa_rectangular_pyramid(l,w,h)",
                    text: "The lsa_rectangular_pyramid function lets you find the lateral surface area of the rectangular pyramid of the parameters inputted and returns it",
                },
                {
                    id: 63,
                    name: "lsa_hexagonal_pyramid(a,h)",
                    text: "The lsa_hexagonal_pyramid function lets you find the lateral surface area of the hexagonal pyramid of the parameters inputted and returns it",
                },
                {
                    id: 64,
                    name: "area_torus(R,r)",
                    text: "The area_torus function lets you find the area of the torus of the parameters inputted and returns it"
                }
            ],
            playing_numbers: [
                {
                    id: 0,
                    name: "lcm(*nums)",
                    text: "The lcm function lets you find the lcm of the numbers specified and returns it",
                },
                {
                    id: 1,
                    name: "hcf(*nums)",
                    text: "The hcf function lets you find the hcf of the numbers specified and returns it",
                },
                {
                    id: 2,
                    name: "prime(num)",
                    text: "The prime function lets you find if the number specified is a prime number or not and prints it",
                },
                {
                    id: 3,
                    name: "armstrong(num)",
                    text: "The larmstrong function lets you find is the number specified is a armstrong number or not and prints it",
                },
                {
                    id: 4,
                    name: "reverse(num)",
                    text: "The reverse function lets you reverse the number specified and returns the list of it",
                },
                {
                    id: 5,
                    name: "sum_digits(num)",
                    text: "The sum_digits function lets you find the sum of the digits of the number specified and returns it",
                },
                {
                    id: 6,
                    name: "even_odd(num)",
                    text: "The even_odd function lets you find if the numbers specified is odd or even and prints it",
                },
                {
                    id: 7,
                    name: "prime_factors(num)",
                    text: "The prime_factors function lets you find the prime factors of the number specified and prints it",
                },
                {
                    id: 8,
                    name: "greatest_number(*nums)",
                    text: "The greatest_number function lets you find the greatest of the numbers specified and returns it",
                },
                {
                    id: 9,
                    name: "lowest_number(*nums)",
                    text: "The lowest_number function lets you find the lowest of the numbers specified and returns it",
                },
                {
                    id: 10,
                    name: "find_number(num,*nums)",
                    text: "The find_number function lets you find if a number is in the numbers specified and if it is, tells its position and prints it",
                },
                {
                    id: 11,
                    name: "how_many_number(*nums)",
                    text: "The how_many_number function lets you find the count of the numbers specified and and returns it",
                },
                {
                    id: 12,
                    name: "average(*nums)",
                    text: "The average function lets you find the average of the numbers specified and and returns it",
                },
                {
                    id: 13,
                    name: "factorial(num)",
                    text: "The factorial function lets you find the factorial of the number specified and and returns it",
                },
                {
                    id: 14,
                    name: "ascii(character)",
                    text: "The ascii function lets you find the ascii value of the character specified and and returns it",
                },
                {
                    id: 15,
                    name: "ascii_character(ascii_value)",
                    text: "The factorial function lets you find the character of the ascii value specified and and returns it",
                },
                {
                    id: 16,
                    name: "perfect(num)",
                    text: "The perfect function lets you find if the number specified is a perfect number or not and returns it",
                },
                {
                    id: 17,
                    name: "factorial(num)",
                    text: "The factorial function lets you find the factorial of the number specified and returns it",
                },
                {
                    id: 18,
                    name: "additive_inverse(num)",
                    text: "The additive_inverse function lets you find the additive inverse of the number specified and returns it",
                },
                {
                    id: 19,
                    name: "multiplicative_inverse(num)",
                    text: "The multiplicative_inverse function lets you find the multiplicative_inverse of the number specified and returns it",
                },
                {
                    id: 20,
                    name: "absolute_value(num)",
                    text: "The absolute_value function lets you find the absolute_value of the number specified and returns it",
                },
                {
                    id: 21,
                    name: "co-prime(*nums)",
                    text: "The co-prime function lets you find if the numbers specified are coprime or not and prints it",
                },
                {
                    id: 22,
                    name: "ascending_order(*nums)",
                    text: "The ascending_order function lets you find the ascending order of the numbers specified and returns it",
                },
                {
                    id: 23,
                    name: "descending_order(*nums)",
                    text: "The descending_order function lets you find the descending order of the numbers specified and returns it",
                },
                {
                    id: 24,
                    name: "even_numbers(*nums)",
                    text: "The even_numbers function lets you find the even numbers from the number specified and returns it",
                },
                {
                    id: 25,
                    name: "multiplication_table(num)",
                    text: "The multiplication_table function lets you find the multiplication table of the number specified and returns it",
                },
                {
                    id: 26,
                    name: "unique_list(*nums)",
                    text: "The unique_list function lets you find the unique elements from the the list specified and returns it",
                },
                {
                    id: 27,
                    name: "twin_prime(num)",
                    text: "The twin_prime function lets you find if the number specified is a twin prime or not and prints it",
                },
                {
                    id: 28,
                    name: "leap_year(year)",
                    text: "The leap_year function lets you find if the year specified is a leap year or not and prints it",
                },
                {
                    id: 29,
                    name: "mean(*nums)",
                    text: "The mean function lets you find the mean of the numbers specified and returns it",
                },
                {
                    id: 30,
                    name: "mode(*nums)",
                    text: "The mode function lets you find the mode of the numbers specified and returns it",
                },
                {
                    id: 31,
                    name: "median(*nums)",
                    text: "The median function lets you find the median of the numbers specified and returns it",
                },
                {
                    id: 32,
                    name: "point_x_y_origin(x1, y1)",
                    text: "The point_x_y_origin function lets you find if the point specified lies on the y axis, x axis or the origin and prints it",
                },
                {
                    id: 33,
                    name: "three_point_straight_line(x1,y1,x2,y2,x3,y3)",
                    text: "The three_point_straight_line function lets you find if the 3 points specified lie on a straight line or not and prints it",
                },
            ],
            string_related: [
                {
                    id: 0,
                    name: "common_letter(text)",
                    text: "The common_letter function lets you find the most common letter in a string of text and prints it"
                },
                {
                    id: 1,
                    name: "common_letter_file(file)",
                    text: "The common_letter_file function lets you find the most common letter in a file and prints it"
                },
                {
                    id: 2,
                    name: "letter_count(text)",
                    text: "The letter_count function lets you find the number of letter in a string of text and prints it"
                },
                {
                    id: 3,
                    name: "letter_count_file(file)",
                    text: "The letter_count_file function lets you find the number of letter in a file and prints it"
                },
                {
                    id: 4,
                    name: "common_word(text)",
                    text: "The common_word function lets you find the most common word in a string of text and prints it"
                },
                {
                    id: 5,
                    name: "common_word_file(file)",
                    text: "The common_word_file function lets you find the most common word in a file and prints it"
                },
                {
                    id: 6,
                    name: "word_count(text)",
                    text: "The word_count function lets you find the number of letter in a string of text and prints it"
                },
                {
                    id: 7,
                    name: "word_count_file(file)",
                    text: "The word_count_file function lets you find the number of letter in a file and prints it"
                },
                {
                    id: 8,
                    name: "find_word(word_to_be_found,file)",
                    text: "The find_word function lets you find if the word is in the file and prints it"
                },
                {
                    id: 9,
                    name: "check(character)",
                    text: "The check function lets you find if a character that is inputted is an uppercase, lowercase, number or a special character and prints it"
                },
                {
                    id: 10,
                    name: "find_uppercase_lowecase(text)",
                    text: "The find_uppercase_lowercase function lets you find the number of uppercase and lowercase characters in a string of text and prints it"
                },
                {
                    id: 11,
                    name: "find_uppercase_lowercase_file(file)",
                    text: "The find_uppercase_lowercase_file function lets you find the number of uppercase and lowercase characters in a file and prints it"
                },
                {
                    id: 12,
                    name: "is_str_palindrome(string)",
                    text: "The is_str_palindrome function lets you find if a string of text is a palindrome or not and prints it"
                },
                {
                    id: 13,
                    name: "is_str_pangram(string)",
                    text: "The is_str_pangram function lets you find if a string is a pangram or noi and prints it"
                },
            ],
            mathematics_daily: [
                {
                    id: 0,
                    name: "profit(cp,sp)",
                    text: "The profit function lets you find the profit when cp and sp are inputted and return it"
                },
                {
                    id: 1,
                    name: "loss(cp,sp)",
                    text: "The loss function lets you find the loss when cp and sp are inputted and return it"
                },
                {
                    id: 2,
                    name: "gain_percentage(profit,cp)",
                    text: "The gain_percentage function lets you find the gain_percentage when profit and cp are inputted and return it"
                },
                {
                    id: 3,
                    name: "loss_percentage(loss,cp)",
                    text: "The loss_percentage function lets you find the loss_percentage when loss and cp are inputted and return it"
                },
                {
                    id: 4,
                    name: "selling_price_when_gain_percentage_and_cp_are_given(gain_percentage,cp)",
                    text: "The selling_price_when_gain_percentage_and_cp_are_given function lets you find the sp when gain% and cp are inputted and return it"
                },
                {
                    id: 5,
                    name: "selling_price_when_loss_percentage_and_cp_are_given(loss_percentage,cp)",
                    text: "The selling_price_when_gain_percentage_and_cp_are_given function lets you find the sp when loss% and cp are inputted and return it"
                },
                {
                    id: 6,
                    name: "cost_price_when_gain_percentage_and_cp_are_given(gain_percentage,sp)",
                    text: "The selling_price_when_gain_percentage_and_cp_are_given function lets you find the cp when gain% and sp are inputted and return it"
                },
                {
                    id: 7,
                    name: "cost_price_when_loss_percentage_and_cp_are_given(loss_percentage,sp)",
                    text: "The selling_price_when_gain_percentage_and_cp_are_given function lets you find the cp when loss% and sp are inputted and return it"
                },
                {
                    id: 8,
                    name: "find_selling_price(mp,discpercent)",
                    text: "The find_selling_price function lets you find the sp when mp and discount% are inputted and return it"
                },
                {
                    id: 9,
                    name: "tax(sp,tax_percent)",
                    text: "The tax function lets you find the tax when sp and tax% are inputted and return it"
                },
                {
                    id: 10,
                    name: "tax_when_sp_and_billamount(sp,bill_amount)",
                    text: "The tax_when_sp_and_billamount function lets you find the tax when sp and bill amount are inputted and return it"
                },
                {
                    id: 11,
                    name: "sp_when_tax_and_billamount(tax_percent,bill_amount)",
                    text: "The sp_when_tax_and_billamount function lets you find the sp when tax% and bill_amount are inputted and return it"
                },
                {
                    id: 12,
                    name: "find_simple_interest(p,r,t)",
                    text: "The find_simple_interest function lets you find the simple interest when principal,rate and time are inputted and return it"
                },
                {
                    id: 13,
                    name: "find_compund_interest(p,r,t)",
                    text: "The find_compund_interest function lets you find the compound interest when principal,rate and time are inputted and return it"
                },
                {
                    id: 14,
                    name: "find_compund_interest_halfyearly(p,r,t)",
                    text: "The find_compund_interest_halfyearly function lets you find the compound interest half-yearly when principal,rate and time are inputted and return it"
                },
                {
                    id: 15,
                    name: "find_compund_interest_quartelry(p,r,t)",
                    text: "The find_compund_interest_quartelry function lets you find the compound interest quarterly when principal,rate and time are inputted and return it"
                },
            ],
            geometry_related: [
                {
                    id: 0,
                    name: "triangle_is_valid(a,b,c)",
                    text: "The triangle_is_valid function lets you find if a triangle is valid or not by inputting all the sides"
                },
                {
                    id: 1,
                    name: "angle_sum_property(a1,a2)",
                    text: "The angle_sum_property function lets you find the 3rd angle by inputting 2 of the known angles and returns it"
                },
                {
                    id: 2,
                    name: "take_out_hypotenuse(a,b)",
                    text: "The take_out_hypotenuse function lets you find the hypotenuse by inputting base and perpendicular and returns it"
                },
                {
                    id: 3,
                    name: "take_out_perpendicular(c,b)",
                    text: "The take_out_perpendicular function lets you find the perpendicular by inputting hypotenuse and base and returns it"
                },
                {
                    id: 4,
                    name: "take_out_base(c,a)",
                    text: "The take_out_base function lets you find the base by inputting the hypotenuse and perpendicular and returns it"
                },
                {
                    id: 5,
                    name: "type_triangle(side1,side2,side3)",
                    text: "The type_triangle function lets you find if a triangle is equilateral, isosceles or scalene by inputting all the sides"
                },
                {
                    id: 6,
                    name: "congruency(sides1,sides2,angles1,angles2)",
                    text: "The congruency function lets you find if a triangle is congruent or not, and if so, which congruence they are by inputting the sides of triangle1 and 2, and angles of triangle 1 and 2"
                },
                {
                    id: 7,
                    name: "sss_congruence(sides1,sides2,angles1,angles2)",
                    text: "The sss_congruency function lets you find if a triangle is congruent by sss congruency or not by inputting the sides of triangle1 and 2, and angles of triangle 1 and 2"
                },
                {
                    id: 8,
                    name: "sas_congruence(sides1,sides2,angles1,angles2)",
                    text: "The sas_congruency function lets you find if a triangle is congruent by sas congruency or not by inputting the sides of triangle1 and 2, and angles of triangle 1 and 2"
                },
                {
                    id: 9,
                    name: "asa_congruence(sides1,sides2,angles1,angles2)",
                    text: "The asa_congruency function lets you find if a triangle is congruent by asa congruency or not by inputting the sides of triangle1 and 2, and angles of triangle 1 and 2"
                },
                {
                    id: 10,
                    name: "rhs_congruence(sides1,sides2,angles1,angles2)",
                    text: "The rhs_congruence function lets you find if a triangle is congruent by rhs congruency or not by inputting the sides of triangle1 and 2, and angles of triangle 1 and 2"
                },
            ],
            conversions: [
                {
                    id: 0,
                    name: "celsius_to_fahrenheit(celsius)",
                    text: "The celsius_to_fahrenheit function lets you find the farenheit equivilant of the celsius temperature and returns it",
                },
                {
                    id: 1,
                    name: "farenheit_to_celsius(farenheit)",
                    text: "The farenheit_to_celsius function lets you find the celsius equivilant of the farenheit temperature and returns it",
                },
                {
                    id: 2,
                    name: "unit_conversions(what_to_convert,value)",
                    text: "The unit_conversions function lets you convert from one unit to another by specifying the unit conversion as string in the first paramtere and the value in the second and returns it",
                },
                {
                    id: 3,
                    name: "binary_to_decimal(num)",
                    text: "The binary_to_decimal function lets you find the decimal equivilant of the binary number inputted and returns it"
                },
                {
                    id: 4,
                    name: "binary_to_octal(num)",
                    text: "The binary_to_octal function lets you find the octal equivilant of the binary number inputted and returns it"
                },
                {
                    id: 5,
                    name: "binary_to_hexadecimal(num)",
                    text: "The binary_to_hexadecimal function lets you find the hexadecimal equivilant of the binary number inputted and returns it"
                },
                {
                    id: 6,
                    name: "decimal_to_binary(num)",
                    text: "The decimal_to_binary function lets you find the binary equivilant of the decimal number inputted and returns it"
                },
                {
                    id: 7,
                    name: "decimal_to_octal(num)",
                    text: "The decimal_to_octal function lets you find the octal equivilant of the decimal number inputted and returns it"
                },
                {
                    id: 8,
                    name: "decimal_to_hexadecimal(num)",
                    text: "The decimal_to_hexadecimal function lets you find the hexadecimal equivilant of the decimal number inputted and returns it"
                },
                {
                    id: 9,
                    name: "octal_to_binary(num)",
                    text: "The octal_to_binary function lets you find the binary equivilant of the octal number inputted and returns it"
                },
                {
                    id: 10,
                    name: "octal_to_decimal(num)",
                    text: "The octal_to_decimal function lets you find the decimal equivilant of the octal number inputted and returns it"
                },
                {
                    id: 11,
                    name: "octal_to_hexadecimal(num)",
                    text: "The octal_to_hexadecimal function lets you find the hexadecimal equivilant of the octal number inputted and returns it"
                },
                {
                    id: 12,
                    name: "hexadecimal_to_binary(num)",
                    text: "The hexadecimal_to_binary function lets you find the binary equivilant of the hexadecimal number inputted and returns it"
                },
                {
                    id: 13,
                    name: "hexadecimal_to_decimal(num)",
                    text: "The hexadecimal_to_decimal function lets you find the decimal equivilant of the hexadecimal number inputted and returns it"
                },
                {
                    id: 14,
                    name: "hexadecimal_to_octal(num)",
                    text: "The hexadecimal_to_octal function lets you find the octal equivilant of the hexadecimal number inputted and returns it"
                },
            ],
            science_related: [
                {
                    id: 0,
                    name: "num_images_multiple_mirrors(num_of_errors)",
                    text: "The num_images_multiple_mirrors function lets you find the number of images formed when multiple mirrors are there and returns it",
                },
                {
                    id: 1,
                    name: "speed(distance, time)",
                    text: "The speed function lets you find the speed when time and distance are given and returns it",
                },
                {
                    id: 2,
                    name: "distance(speed, time)",
                    text: "The distance function lets you find the distance when speed and time are given and returns it",
                },
                {
                    id: 3,
                    name: "time(distance, speed)",
                    text: "The time function lets you find the time when speed and distance are given and returns it",
                },
            ]
        }

    }
    
    render() {
        
        const arithmetic_operators_constant = this.state.arithmetic_operators.map((arithmetic_operators_map)=>{
            return (
                <div key={arithmetic_operators_map.id}>
                    <hr />
                    <h2><pre><code><font color="white">{arithmetic_operators_map.name}</font></code></pre></h2>
                    <div>{arithmetic_operators_map.text}</div>
                </div>
            );
        });

        const area_perimeter_volume_constant = this.state.area_perimeter_volume.map((area_perimeter_volume_map)=>{
            return (
                <div key={area_perimeter_volume_map.id}>
                    <hr />
                    <h2><pre><code><font color="white">{area_perimeter_volume_map.name}</font></code></pre></h2>
                    <div>{area_perimeter_volume_map.text}</div>
                </div>
            );
        });

        const playing_numbers_constant = this.state.playing_numbers.map((playing_numbers_map)=>{
            return (
                <div key={playing_numbers_map.id}>
                    <hr />
                    <h2><pre><code><font color="white">{playing_numbers_map.name}</font></code></pre></h2>
                    <div>{playing_numbers_map.text}</div>
                </div>
            );
        });

        const string_related_constant = this.state.string_related.map((string_related_map)=>{
            return (
                <div key={string_related_map.id}>
                    <hr />
                    <h2><pre><code> <font color="white">{string_related_map.name}</font></code></pre></h2>
                    <div>{string_related_map.text}</div>
                </div>
            );
        });

        const mathematics_daily_constant = this.state.mathematics_daily.map((mathematics_daily_map)=>{
            return (
                <div key={mathematics_daily_map.id}>
                    <hr />
                    <h2><pre><code> <font color="white">{mathematics_daily_map.name}</font></code></pre></h2>
                    <div>{mathematics_daily_map.text}</div>
                </div>
            );
        });

        const geometry_related_constant = this.state.geometry_related.map((geometry_related_map)=>{
            return (
                <div key={geometry_related_map.id}>
                    <hr />
                    <h2><pre><code> <font color="white">{geometry_related_map.name}</font></code></pre></h2>
                    <div>{geometry_related_map.text}</div>
                </div>
            );
        });

        const conversions_constant = this.state.conversions.map((conversions_map)=>{
            return (
                <div key={conversions_map.id}>
                    <hr />
                    <h2><pre><code><font color="white">{conversions_map.name}</font></code></pre></h2>
                    <div>{conversions_map.text}</div>
                </div>
            );
        });

        const science_related_constant = this.state.science_related.map((science_related_map)=>{
            return (
                <div key={science_related_map.id}>
                    <hr />
                    <h2><pre><code><font color="white">{science_related_map.name}</font></code></pre></h2>
                    <div>{science_related_map.text}</div>
                </div>
            );
        });

        return(
            <div id="docs-body-id">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#arithmetic-operators">Basic Arithmetic Operators</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#area-volume">Mensuration</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#playing-numbers">Playing with Numbers</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#string-related">String Related</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#mathematics-daily">Mathematics In Daily Life</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#geometry-related">Geometry Related</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#conversions">Conversions</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#science-related">Science Related</a></li>
                        <li className="nav-item-docs nav-item ctrl-f-docs-message"><p>Use ctrl+f to search for respective functions</p></li>
                    </ul>
                </div>
                <div id="documentation-container">
                    <div className="documentation-section-class" id="arithmetic-operators">
                        <h1>Basic Arithmetic Operators</h1>
                        <div>{arithmetic_operators_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="area-volume">
                        <h1>Area, Perimeter, Circumference, Volume and Surface Area of 2D and 3D shapes</h1>
                        <div>{area_perimeter_volume_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="playing-numbers">
                        <h1>Playing With Numbers</h1>
                        <div>{playing_numbers_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="string-related">
                        <h1>String Related Functions</h1>
                        <div>{string_related_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="mathematics-daily"> 
                        <h1>Mathematics in Daily Life</h1>
                        <div>{mathematics_daily_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="geometry-related">
                        <h1>Geometry Related Function</h1>
                        <div>{geometry_related_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="conversions">
                        <h1>Conversions</h1>
                        <div>{conversions_constant}</div>
                    </div>
                    <div className="documentation-section-class" id="science-related">
                        <h1>Science Related Functions</h1>
                        <div>{science_related_constant}</div>
                    </div>
                </div>
            </div>
        )
    
    }

}

export default Docs