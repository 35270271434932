import React, { Component } from 'react';
import './NavBar.css'
import {Container, Navbar, Nav, NavItem, NavLink,} from 'reactstrap'
import { AiFillGithub } from 'react-icons/ai';
import { Affix } from 'rsuite';
import {Link, withRouter} from 'react-router-dom'

class NavigationBar extends Component {       

    render() {
        
        return(
                <Affix>
                    <Container fluid id="navbar-id">
                        <Navbar expand = 'lg' sticky="top" className="sticky-nav" data-spy="affix" data-offset-top="197">
                            <Link to="/" id="logo-name">The Invaderssss</Link>
                                <Nav navbar className="ml-auto">
                                    <NavItem className="nav-item hvr-ripple-out">
                                        <Link to="/" className="nav-link">Home</Link>
                                    </NavItem>
                                    <NavItem className="nav-item hvr-ripple-out">
                                        <Link className="nav-link" to="/installation">Installation</Link>
                                    </NavItem>
                                    <NavItem className="nav-item hvr-ripple-out">
                                        <Link to="/docs" className="nav-link">Docs</Link>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="https://github.com/kav2007/invaderssss" target="_blank">
                                            <AiFillGithub />
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                        </Navbar>
                    </Container>
                </Affix>
        )
    
    }

}
export default withRouter(NavigationBar)