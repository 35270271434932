import React, { Component } from 'react'
import '@animxyz/react/dist/ReactAnimXyz'
import './Footer.css'
import { FaDiscord } from 'react-icons/fa'
import { GrSkype } from 'react-icons/gr'
import { AiFillGithub, AiFillHeart } from 'react-icons/ai'
import { Col, Row } from 'reactstrap'
import {Link, withRouter} from 'react-router-dom'

class Footer extends Component{
    
    render() {
        
        return(
            <footer id="footer-id">
                {/* <hr className="footer-starting"/> */}
                <Row id="social-media-section">
                    <Col xs="4"><a href="http://discord.gg/JqUFM5m" target="_blank" rel="noreferrer"><FaDiscord className="social-media-button discord-button"/></a></Col>
                    <Col xs="4"><a href="https://join.skype.com/WQEKABRs6tyE" target="_blank" rel="noreferrer"><GrSkype className="social-media-button skype-button"/></a></Col>
                    <Col xs="4"><a href="https://github.com/kav2007/invaderssss" target="_blank" rel="noreferrer"><AiFillGithub className="social-media-button github-button"/></a></Col>
                </Row>
                <section>
                    <Link to="/" className="links-footer hvr-underline-from-center">Home</Link>
                    <Link to="/installation" className="links-footer hvr-underline-from-center">Installing Invaderssss</Link>
                    <Link to="/docs" className="links-footer hvr-underline-from-center">Documentation</Link>
                    <a href="https://drive.google.com/file/d/1jicS4eQUnb_J_Lens_Ss3a10UEuc3rOj/view?usp=sharing" target="_blank" rel="noreferrer"className="links-footer hvr-underline-from-center">Download Zip</a>
                </section>
                <section>
                    <div className="tagline-footer">The Basic-est python library that anybody and everybody can use</div>
                    <div>&copy; <span className="logo-name">The Invadersssss 2021</span></div>
                    <div>Made with <AiFillHeart /> by <span className="logo-name">The Invaderssss Crew</span></div>
                </section>
            </footer>
        )
    
    }

}

export default withRouter(Footer)