import React, { Component } from 'react'
import './HowTo.css'
import '@animxyz/react/dist/ReactAnimXyz'
import {} from 'reactstrap/dist/reactstrap.js'
import step1Img from './step1.jpg'
import step2Img from './step2.jpg'
import step3Img from './step3.PNG'

class HowTo extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        
        return(
            <div className="installation">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#arithmetic-operators">Requirements of Invaderssss</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#area-volume">Installing Invaderssss</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#playing-numbers">Importing Invaderssss</a></li>
                        <li className="nav-item-docs nav-item"><a className="nav-link-docs nav-link" href="#string-related">Using Invaderssss</a></li>
                    </ul>
                </div>
                <div className="main-text-installation">
                    <h1>Installing Invaderssss</h1>
                    <hr />
                    <p>Invaderssss is a python library that's lightweight which anybody and everybody can use. We highly encourage you to use invaderssss which has a easy-to-navigate documentation. We highly encourage you to install invaderssss as it is specified in these instructions</p>
                    <div>
                        <h2>Requirements of Invaderssss</h2>
                        <hr />
                        <p>The Invaderssss has not too many requirements. The basic requirements are: </p>
                        <ul>
                            <li><a href="https://www.python.org/downloads/" target="_blank" rel="noreferrer">Python 3.x</a></li>
                            <li>A code editor of your own choice: <a href="https://atom.io/" target="_blank" rel="noreferrer"> Atom</a>, <a href="https://www.sublimetext.com/3" rel="noreferrer" target="_blank">Sublime</a>, <a href="https://code.visualstudio.com/Download" target="_blank" rel="noreferrer">VS Code</a></li>
                        </ul>
                    </div>
                    <div>
                        <h2>Installing Invaderssss</h2>
                        <hr/>
                        <p>The Invaderssss can be installed via either zip or git cli.</p>
                        <ul>
                            <li><a href = "https://drive.google.com/file/d/1jicS4eQUnb_J_Lens_Ss3a10UEuc3rOj/view?usp=sharing" target="_blank" rel="noreferrer">Download Zip</a></li>
                            <li>git clone https://github.com/kav2007/invaderssss.git</li>
                        </ul>
                    </div>
                    <div>
                        <h2>Importing Invaderssss</h2>
                        <hr />
                        <p>After installing Invaderssss, you should open the folder in your favorite code editor, and I shall use Visual Studio Code to display how.</p>
                        <ul>
                            <li>
                                Click on the File Option:
                                <br />
                                <img src={step1Img} alt={"Step1"}/>
                            </li>
                            <li>
                                Click on the Open Folder Option and open the Invaderssss folder
                                <br />
                                <img src={step2Img} alt={"Step2"}/>
                            </li>
                            <li>
                                You will see an example.py which has detailed instructions as to how you need to import invaderssss. Please follow the instructions
                                <br />
                                <img src={step3Img} alt={"Step3"}/>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2>Using Invaderssss</h2>
                        <hr />
                        <p>You should use the invaderssss' functions as the following</p>
                        <ul>
                            <li>
                                Using the example.py instructions if not used the codeWord for yourself
                                <br />
                                Use invaderssss.functionName
                            </li>
                            <li>
                                Using the example.py instructions and used the codeWord for yourself
                                <br/>
                                Use codeWord.functionName
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    
    }

}

export default HowTo